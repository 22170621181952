import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'

import H2 from '@components/core/headings/h2'

import * as S from './styles'

import { INSURANCE_CONTRIBUTIONS_ID } from '@utils/constants'

export default function InsuranceContributions() {
  const { insuranceContributionsImage } = useStaticQuery(graphql`
    query {
      insuranceContributionsImage: file(relativePath: { eq: "insurance-contributions.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <S.InsuranceContributionsSection id={INSURANCE_CONTRIBUTIONS_ID}>
      <H2>Podpora pojišťoven pro rok 2022</H2>
      <GatsbyImage
        fluid={insuranceContributionsImage.childImageSharp.fluid}
        alt="Přehled podpor pojišťoven pro rok 2022"
      />
    </S.InsuranceContributionsSection>
  )
}
