import React from 'react'

export default function useScrollIntoView(elementId: string, options?: ScrollIntoViewOptions) {
  React.useLayoutEffect(() => {
    if (!location.hash.includes(elementId)) {
      return
    }

    const element = document.getElementById(elementId)

    if (!element) {
      return
    }

    element.scrollIntoView(options)
  }, [elementId, options])
}
