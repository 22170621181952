import styled from 'styled-components'

import Section from '@components/core/section'

import theme from '@theme'
import { toRem } from '@theme/utils'

export const InsuranceContributionsSection = styled(Section)`
  width: min(fit-content, ${toRem(1280)});
  margin: 0 auto;
  background-color: ${theme.colors.white[900]};
`
