import React from 'react'

import Layout from '@components/core/layout'
import SEO from '@components/core/seo'
import ServicesList from '@components/pages/cenik-sluzeb/services-list'
import InsuranceContributions from '@components/pages/cenik-sluzeb/insurance-contributions'

import useScrollIntoView from '@hooks/use-scroll-into-view'
import { INSURANCE_CONTRIBUTIONS_ID } from '@utils/constants'

export default function ServicesListPage() {
  useScrollIntoView(INSURANCE_CONTRIBUTIONS_ID, { behavior: 'smooth' })

  return (
    <>
      <SEO title="Ceník služeb" />
      <Layout>
        <ServicesList />

        <InsuranceContributions />
      </Layout>
    </>
  )
}
